<template>
  <div class="footer">
    <div class="footerLeft">
      <p class="synopsis">
        *This includes all personal income, including salary, part-time pay,
        retirement investments, and rental properties. You do not need to
        include alimony, child support, or separate maintenance income unless
        you want to have it considered as a basis for repaying a loan.
      </p>
      <ul class="menu">
        <el-row class="menuList">
          <el-col :span="6">
            <li>
              <el-link style="color: #fff" @click="goRouter('howItWorks')"
                >HOW IT WORKS
              </el-link>
            </li>
          </el-col>
          <el-col :span="6">
            <li>
              <el-link style="color: #fff" @click="goRouter('whoWeAre')"
                >WHO WE ARE
              </el-link>
            </li>
          </el-col>
          <el-col :span="6">
            <li @click="goRouter('rates')">
              <el-link style="color: #fff">RATES</el-link>
            </li>
          </el-col>
          <el-col :span="6">
            <li @click="goRouter('contactUs')">
              <el-link style="color: #fff">CONTACT US</el-link>
            </li>
          </el-col>
        </el-row>
        <div class="menuList">
          <!-- <el-col :span="6">
            <li>
              <el-link
                style='color:#fff'
                @click="goRouter('siteMaps')">SITE MAP</el-link>
            </li>
          </el-col> -->
          <el-col :span="6">
            <li @click="goRouter('privacyPolicy')">
              <el-link style="color: #fff">PRIVACY POLICY</el-link>
            </li>
          </el-col>
          <el-col :span="6">
            <li @click="goRouter('termsOfUse')">
              <el-link style="color: #fff">TERMS OF USE </el-link>
            </li>
          </el-col>
          <el-col :span="6">
            <li>
              <el-link style="color: #fff" @click="goRouter('accessibility')"
                >ACCESSIBILITY
              </el-link>
            </li>
          </el-col>
        </div>
        <!-- <div class='menuList'>
          <el-col :span="6">
            <li>
              <el-link
                style='color:#fff'
                @click="goHttp">LEGAL</el-link>
            </li>
          </el-col>
        </div> -->
      </ul>
      <div class="body">
        <div v-html="footerHtml"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getTemplate } from '@/api/template'
export default {
  data() {
    return {
      footerHtml: ''
    };
  },
  created () {
    getTemplate(3).then(res => {
      if (res.data.code === '100200') {
        this.footerHtml = res.data.data.content
      }
    })
  },
  methods: {
    goRouter(value) {
      this.$router.push({ name: value });
    },
    goHttp() {
      window.open("http://zoomercredit.com/legal");
    },
    getMissouriPDF() {
      this.$router.push({ name: "missouri" });
    },
    getUtahPDF() {
      this.$router.push({ name: "utah" });
    },
  },
};
</script>

<style lang="scss" scoped>
@import  'pdfh5/css/pdfh5.css';
#ifadd {
  width: 80%;
  height: 100%;
  z-index: 999999;
  position: absolute;
}
.link {
  cursor: pointer;
  font-size: 1em;
}
.style {
  text-decoration: underline;
}
.document {
  position: absolute;
  width: 80%;
  height: 100%;
}
.close {
  position: absolute;
  top: 2%;
  right: 2%;
  font-size: 2em;
  z-index: 9999;
}
.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 5% 10% 3% 10%;
  box-sizing: border-box;
  background: #3e5166;
  color: #fff;
  position: relative;
  .menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 2%;
  }
  .text {
    margin-bottom: 1em;
    line-height: 1.5em;
  }
  li {
    list-style: none;
    font-size: 1.25em;
    color: #fff;
    line-height: 2em;
  }
  .license {
    display: flex;
    margin-bottom: 1em;
  }
  .menuList {
    width: 100%;
    display: flex;
    .el-link {
      text-decoration: none !important;
      &.el-link.is-underline:hover:after {
        border-bottom: none;
      }
    }
    li {
      margin-right: 10%;
    }
  }
  .footerLeft {
    // width: 40%;
    display: flex;
    flex-direction: column;
    .appName {
      font-size: 2em;
      color: #fff;
      margin-bottom: 2%;
    }
    .synopsis {
      font-size: 1.25em;
      color: #fff;
      line-height: 2em;
      margin-bottom: 2%;
    }
    p {
      font-size: 1.4em;
      color: #fff;
    }
  }
  .footerRight {
    width: 40%;
    display: flex;
    justify-content: space-between;
    .title {
      font-size: 1.4em;
      margin-bottom: 2em;
    }
    ul {
      li {
        font-size: 1.25em;
        color: #fff;
        line-height: 2em;
      }
    }
    .phone {
      font-size: 1.25em;
      line-height: 2em;
      color: #fff;
      .number {
        color: #459eff;
      }
    }
  }
}
@media screen and (min-width: 769px) and (max-width: 1366px) {
  .footer {
    padding: 8% 8%;
  }
  .text1 {
    margin-bottom: 1em;
    line-height: 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    .logo {
      width: 15%;
      margin: 0 0.8em;
    }
  }
}
// 兼容手机
@media screen and (max-width: 768px) {
  .footer .footerLeft .synopsis {
    font-size: 1em;
    line-height: 1.5em;
  }
  .footer .footerLeft .appName {
    font-size: 1.5em;
  }
  .footer .footerLeft .label {
    font-size: 1em;
  }
  .menu {
    display: block;
  }
  .text1 {
    font-size: 12px !important;
    margin-bottom: 1em;
    line-height: 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    .logo {
      width: 20%;
      margin: 0 0.8em;
    }
  }
}
</style>